import * as React from "react";
import { Show, SimpleShowLayout, TextField, ImageField, DateField, EditButton, RichTextField } from 'react-admin';


const AndoReportView = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="이름" source="name" />
            <TextField label="주소" source="address" />
            <ImageField  label="신고사진" source="images" src="filepath"/>
            <TextField label="내용" source="content" />
            <TextField label="이메일" source="email" />
            <TextField label="상세주소" source="address2" />
        </SimpleShowLayout>
    </Show>
);


export default AndoReportView;