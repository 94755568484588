import React, { useCallback, useState } from 'react';
import { Datagrid, Edit, Create, SimpleForm, NumberInput, Toolbar, SelectInput, SaveButton, TextInput, DateInput } from 'react-admin';
import {minValue, maxValue, required} from 'react-admin'
import { useForm } from 'react-final-form';
import { GeoPoint } from 'parse';
import {getMainArea, getSubArea} from './areaCodes';

const PandemicVisitSpotCreate = props => {
    let [areaIndex, setAreaIndex] = useState(0);
    let [subAreas, setSubAreas] = useState([]);

    let mainArea = getMainArea();
    // 지역 취소를 위한 데이터 추가
    mainArea.splice(0, 0, {id: null, name: '-'});

    const handleMainAreaChagne = (evt) => {
        console.log('handle main area change :', evt.target);
        const mainArea = evt.target.value;

        if ( mainArea ) {
            let data = getSubArea(mainArea);
            // 지역 취소를 위한 데이터 추가
            console.log('subareas :', data);
            if ( data.length > 0 && data[0].id != null ) {
                data.splice(0, 0, {id: null, name: '-'});
            }
            setSubAreas(data);
        }
        else {
            setSubAreas([]);
        }
    }

    return (
        <Create aside={<Aside />} {...props}>
            <SimpleForm toolbar={<SpotCreateToolbal />} submitOnEnter={false}>
                <DateInput label="날짜(년.월.일)" source="date" defaultValue={new Date()}/>
                <TextInput label="시간" source="time" />
                <TextInput label="이름" source="name" validate={required()} />
                <SelectInput label="지역(대)" source="area" onChange={handleMainAreaChagne}  choices={mainArea} />
                <SelectInput label="지역(중)" source="subarea" choices={subAreas} />
                <TextInput label="내용" source="content" options={{ multiline: true }} />
                <NumberInput label="위도" source="location.latitude" validate={[required(), minValue(-180), maxValue(180)]} />
                <NumberInput label="경도" source="location.longitude" validate={[required(), minValue(-180), maxValue(180)]} />
            </SimpleForm>
        </Create>
    )
}

const SpotCreateToolbal = props => {
    const form = useForm();

    const handleSave = useCallback(
        () => {
            try {
                const state = form.getState().values;

                const date = state.date;
                if ( !( date instanceof Date )) {
                    form.change('date', new Date(date));
                }
                
                if ( state && state.location && state.location.latitude && state.location.longitude ) {
                    const latitude = state.location.latitude;
                    const longitude = state.location.longitude;            
                    form.change('location', new GeoPoint({latitude, longitude}));
                    form.submit();                
                }                
            } catch (error) {
                alert('error :', error.message);
            }
        });

    return (
        <Toolbar {...props}>
            <SaveButton 
                submitOnEnter={false}
                handleSubmitWithRedirect={handleSave} />
        </Toolbar>
    )
}

const Aside = () => (
    <div style={{ width: 100, margin: '1em' }}>
        <h6>확진자 방문</h6>
        <p variant="body2">
            사이드 Aside 테스트
        </p>
    </div>
);

export default PandemicVisitSpotCreate