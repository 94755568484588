import {Parse} from 'parse';

const loginHander = async ({username, password}) => {        
    try {
        console.log('trying to login:', username, password);
        const user = await Parse.User.logIn(username, password);
        console.log('login success');
    }
    catch (error) {
        alert(error.message);
        console.error('login error:', error);
    }    
}

const checkAuthHandler = () => {
    try {
        console.log('check auth', Parse, Parse.User, Parse.User.current());
        const current = Parse.User.current();
        if ( current ) {
            return Promise.resolve();
        }
        else {
            return Promise.reject();
        }
    }
    catch ( error ) {
        return Promise.reject();
    }
}

const logoutHandler = async () => {
    try {
        const ret = await Parse.User.logOut();
        console.log('logout result :', ret);
        return Promise.resolve();
    } catch (error) {
        console.error('logout error:', error);
    }
}

const handleErrorCheck = (error) => {
    const status = error.status;
    if ( 200 <= status && status < 300 ) {
        return Promise.resolve();
    }
    return Promise.reject();
}

const handlePermission = (param) => {
    return Promise.resolve();
}

export default {
    login: loginHander,
    logout: logoutHandler,
    checkAuth: checkAuthHandler,
    checkError: handleErrorCheck,
    getPermissions: handlePermission,
};