// in posts.js
import React from 'react';
import { List, Datagrid, Edit, Create, SimpleForm, DateField, TextField, EditButton, TextInput, DateInput } from 'react-admin';

const PandemicVisitSpots = (props) => (
    <List {...props}>
        <Datagrid>
            <DateField label="날짜" source="date" />
            <TextField label="시간" source="time" />
            <TextField label="이름" source="name" />
            <TextField label="지역(대)" source="area" />
            <TextField label="지역(중)" source="subarea" />
            <TextField label="내용" source="content" />
            <TextField label="위도(Latitude)" source="location.latitude" />
            <TextField label="경도(Longitude)" source="location.longitude" />
        </Datagrid>        
    </List>
);

export default PandemicVisitSpots;