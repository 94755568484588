import React, { Component } from 'react'
import { List, Datagrid, Edit, Create, SimpleForm, DateField, TextField, EditButton, TextInput, DateInput } from 'react-admin';

const AndoReport = (props) => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField label="이름" source="name" />
            <TextField label="주소" source="address" />
            <TextField label="내용" source="content" />
            <TextField label="이메일" source="email" />
            <TextField label="상세주소" source="address2" />
            <TextField label="상태" source="state" />
        </Datagrid>        
    </List>
);


export default AndoReport;