

const areaData = {
    '서울': [
        {id: '강남구', name: '강남구'},
        {id: '강동구', name: '강동구'},
        {id: '강북구', name: '강북구'},
        {id: '강서구', name: '강서구'},
        {id: '관악구', name: '관악구'},
        {id: '광진구', name: '광진구'},
        {id: '구로구', name: '구로구'},
        {id: '금천구', name: '금천구'},

        {id: '노원구', name: '노원구'},

        {id: '도봉구', name: '도봉구'},
        {id: '동대문구', name: '동대문구'},
        {id: '동작구', name: '동작구'},

        {id: '마포구', name: '마포구'},

        {id: '서대문구', name: '서대문구'},
        {id: '서초구', name: '서초구'},
        {id: '성동구', name: '성동구'},

        {id: '성북구', name: '성북구'},
        {id: '송파구', name: '송파구'},

        {id: '양천구', name: '양천구'},
        {id: '영등포구', name: '영등포구'},
        {id: '용산구', name: '용산구'},
        {id: '은평구', name: '은평구'},

        {id: '종로구', name: '종로구'},
        {id: '중구', name: '중구'},
        {id: '중랑구', name: '중랑구'}
    ]
}

export function getMainArea() {
    return [
        {id: '서울', name: '서울'}
    ]
}

export function getSubArea(main) {
    return areaData[main];
}