import React, {Component} from 'react';
import {Admin, Resource, Delete } from 'react-admin';
import {Parse} from 'parse';
import {ParseAuth, ParseClient} from 'ra-data-parse';

import PandemicVisitSpots from './PandemicVisitSpots';
import PandemicVisitSpotCreate from './PandemicVisitSpotCreate';
import AuthProvider from './AuthProvider';
import andoConfig from'./andoConfig';
import AndoReport from './AndoReport';
import AndoReportView from './AndoReportView';

Parse.initialize(andoConfig.appId, andoConfig.javascriptKey);
Parse.serverURL = andoConfig.parseServerUrl;

class App extends Component {
    constructor() {
        super();
        const parseConfig = {
            URL: andoConfig.parseServerUrl,
            APP_ID: andoConfig.parseAppId,
            JAVASCRIPT_KEY: andoConfig.parseJavascriptKey
        };

        const dataProvider = ParseClient(parseConfig);
        // const authProvider = ParseAuth(parseConfig);

        this.state = {
            dataProvider,
            // authProvider
        }
    }

    componentDidMount = async () => {
        try {
        } catch (error) {
            console.error('buildGraphcoolProvider', error);
        }
    }

    render() {
        if (!this.state.dataProvider) {
            return <div>Loading</div>;
        }
        return (
            <Admin
                authProvider={AuthProvider}
                dataProvider={this.state.dataProvider}>
                <Resource name="PandemicVisitSpot" list={PandemicVisitSpots} create={PandemicVisitSpotCreate} show={AndoReportView} />
                <Resource name="AndoReport" list={AndoReport} show={AndoReportView} />
            </Admin>
        );
    }
}

export default App;
